$COLOR_MASTHEAD_BORDER: fade-out($COLOR_INVERTED_TEXT, 0.76);

.Masthead {
    position: relative;
    background-color: var(--masthead-background-color, transparent);
    z-index: 1;

    &-background {
        background: center / cover no-repeat var(--masthead-background);
        @include breakpoint-mobile-only {
            height: var(--height-masthead-mobile, #{$MASTHEAD_HEIGHT_MOBILE});
        }
        @include breakpoint-tablet-only {
            height: var(--height-masthead-tablet, #{$MASTHEAD_HEIGHT_TABLET});
        }
        @include breakpoint-desktop-up {
            @include FULL_COVER;
        }
    }

    &-foreground {
        position: relative;

        @include breakpoint-mobile-only {
            margin-top: -$MASTHEAD_OVERLAP_MOBILE;
        }
        @include breakpoint-tablet-only {
            margin-top: -$MASTHEAD_OVERLAP_TABLET;
        }
    }
    &-overlay {
        position: relative;

        @include breakpoint-mobile-only {
            background: linear-gradient(to bottom, transparent 0% 40px, var(--masthead-overlay-color, #{$COLOR_INVERTED_BACKGROUND}) 72px);
        }
        @include breakpoint-tablet-only {
            background: linear-gradient(to bottom, transparent 0% 56px, var(--masthead-overlay-color, #{$COLOR_INVERTED_BACKGROUND}) 88px);
        }
    }
    &-content {
        position: relative;
        text-align: center;
        margin: 0 auto;

        @include breakpoint-mobile-only {
            width: $CONTENT_WIDTH_MOBILE;
        }
        @include breakpoint-tablet-only {
            width: $CONTENT_WIDTH_TABLET;
        }
        @include breakpoint-desktop-up {
            width: $CONTENT_WIDTH_DESKTOP;
        }
        @include breakpoint-wide-up {
            width: $CONTENT_WIDTH_WIDE;
        }
    }

    &-inner {
        position: relative;
        text-align: center;
    }
    &-logo {

    }
    &-logoImage {
        width: 300px;
        vertical-align: bottom;

        @include breakpoint-tablet-up {
            width: 320px;
        }
        @include breakpoint-desktop-up {
            width: 400px;
        }
    }

    &-subtitle {
        color: var(--color-masthead-title, $COLOR_INVERTED_TITLE);
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        border-top: solid 1px var(--color-masthead-border, $COLOR_MASTHEAD_BORDER);
        border-bottom: solid 1px var(--color-masthead-border, $COLOR_MASTHEAD_BORDER);
        padding: $SPACE_MEDIUM 0;

        @include breakpoint-mobile-only {
            margin-top: $CONTENT_GUTTER_MOBILE;
            margin-bottom: $CONTENT_GUTTER_MOBILE;
        }
        @include breakpoint-tablet-only {
            margin-top: $CONTENT_GUTTER_TABLET;
            margin-bottom: $CONTENT_GUTTER_TABLET;
        }
        @include breakpoint-desktop-only {
            margin-top: $CONTENT_GUTTER_DESKTOP;
            margin-bottom: $CONTENT_GUTTER_DESKTOP;
        }
        @include breakpoint-wide-up {
            margin-top: $CONTENT_GUTTER_WIDE;
            margin-bottom: $CONTENT_GUTTER_WIDE;
        }
    }

    &-buttons {
        position: relative;
    }
    &-footer {
        position: relative;
    }

    &--standard {
        .Masthead {
            &-inner {
                @include breakpoint-desktop-up {
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: center;
                    justify-content: center;

                    width: $GRID_DESKTOP_FIVE_COLUMNS;
                    margin-right: auto;
                    height: var(--height-masthead-max, #{$MASTHEAD_HEIGHT_MAX});
                }
            }
            &-overlay {
                @include breakpoint-mobile-only {
                    background: linear-gradient(to bottom, transparent 0% 40px, var(--masthead-overlay-color, #{$COLOR_INVERTED_BACKGROUND}) 72px);
                }
                @include breakpoint-tablet-only {
                    background: linear-gradient(to bottom, transparent 0% 56px, var(--masthead-overlay-color, #{$COLOR_INVERTED_BACKGROUND}) 88px);
                }
            }
        }
    }

    &--centered {
        .Masthead {
            &-inner {
                @include breakpoint-desktop-up {
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: center;
                    justify-content: flex-end;

                    width: $GRID_DESKTOP_FIVE_COLUMNS;
                    margin-left: auto;
                    margin-right: auto;
                    height: var(--height-masthead-max, #{$MASTHEAD_HEIGHT_MAX});
                    padding-bottom: $CONTENT_GUTTER_DESKTOP;
                }
            }
        }
    }

    &--footer {
        @include breakpoint-mobile-only {
            margin-top: 0;
        }
        .Masthead {
            &-inner {
                @include breakpoint-desktop-up {
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: center;
                    justify-content: center;

                    width: $GRID_DESKTOP_FIVE_COLUMNS;
                    margin-right: auto;
                    height: var(--height-masthead-max, #{$MASTHEAD_HEIGHT_MAX});
                }
            }
            &-content {
                padding-bottom: 60px;
                @include breakpoint-desktop-up {
                    padding-bottom: 80px;
                }
            }
        }
    }

    &--footerMirrored {
        @include breakpoint-mobile-only {
            margin-top: 0;
        }
        .Masthead {
            &-inner {
                @include breakpoint-desktop-up {
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: center;
                    justify-content: center;

                    width: $GRID_DESKTOP_FIVE_COLUMNS;
                    margin-left: auto;
                    height: var(--height-masthead-max, #{$MASTHEAD_HEIGHT_MAX});
                }
            }
            &-content {
                padding-bottom: 60px;
                @include breakpoint-desktop-up {
                    padding-bottom: 80px;
                }
            }
        }
    }

    &--footerCentered {
        @include breakpoint-mobile-only {
            margin-top: 0;
        }
        .Masthead {
            &-inner {
                @include breakpoint-desktop-up {
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: center;
                    justify-content: flex-end;

                    width: $GRID_DESKTOP_FIVE_COLUMNS;
                    margin-left: auto;
                    margin-right: auto;
                    height: var(--height-masthead-max, #{$MASTHEAD_HEIGHT_MAX});
                    padding-bottom: $CONTENT_GUTTER_DESKTOP;
                }
            }
            &-content {
                padding-bottom: 60px;
                @include breakpoint-desktop-up {
                    padding-bottom: 80px;
                }
            }
        }
    }

}
