$FONT_FAMILY_FALLBACK: Arial, Helvetica, sans-serif !default;

$FONT_FAMILY_BODY: "Roboto", $FONT_FAMILY_FALLBACK !default;
$FONT_FAMILY_BODY_SC: "Roboto", "Noto Sans SC", $FONT_FAMILY_FALLBACK !default;
$FONT_FAMILY_BODY_TC: "Roboto", "Noto Sans TC", $FONT_FAMILY_FALLBACK !default;
$FONT_FAMILY_BODY_JP: "Roboto", "Noto Sans JP", $FONT_FAMILY_FALLBACK !default;
$FONT_FAMILY_BODY_KR: "Roboto", "Noto Sans KR", $FONT_FAMILY_FALLBACK !default;

$FONT_FAMILY_TITLE: "Roboto", $FONT_FAMILY_FALLBACK !default;
$FONT_FAMILY_TITLE_SC: "Roboto", "Noto Sans SC", $FONT_FAMILY_FALLBACK !default;
$FONT_FAMILY_TITLE_TC: "Roboto", "Noto Sans TC", $FONT_FAMILY_FALLBACK !default;
$FONT_FAMILY_TITLE_JP: "Roboto", "Noto Sans JP", $FONT_FAMILY_FALLBACK !default;
$FONT_FAMILY_TITLE_KR: "Roboto", "Noto Sans KR", $FONT_FAMILY_FALLBACK !default;

$FONT_FAMILY_FORM: $FONT_FAMILY_BODY !default;
$FONT_FAMILY_FORM_SC: $FONT_FAMILY_BODY_SC !default;
$FONT_FAMILY_FORM_TC: $FONT_FAMILY_BODY_TC !default;
$FONT_FAMILY_FORM_JP: $FONT_FAMILY_BODY_JP !default;
$FONT_FAMILY_FORM_KR: $FONT_FAMILY_BODY_KR !default;

$FONT_FAMILY_BUTTON: $FONT_FAMILY_TITLE !default;
$FONT_FAMILY_BUTTON_SC: $FONT_FAMILY_TITLE_SC !default;
$FONT_FAMILY_BUTTON_TC: $FONT_FAMILY_TITLE_TC !default;
$FONT_FAMILY_BUTTON_JP: $FONT_FAMILY_TITLE_JP !default;
$FONT_FAMILY_BUTTON_KR: $FONT_FAMILY_TITLE_KR !default;

$FONT_SIZE_BASE: 16px !default;
$FONT_SIZE_DESKTOP: 18px !default;
$FONT_SIZE_H1: 3.0rem !default; // 48px
$FONT_SIZE_H2: 2.5rem !default; // 40px
$FONT_SIZE_H3: 2.0625rem !default; // 33px
$FONT_SIZE_H4: 1.75rem !default; // 28px
$FONT_SIZE_H5: 1.4375rem !default; // 23px
$FONT_SIZE_H6: 1.1875rem !default;  // 19px
$FONT_SIZE_H1_DESKTOP: 3.0rem !default; // 54px
$FONT_SIZE_H2_DESKTOP: 2.5rem !default; // 45px
$FONT_SIZE_H3_DESKTOP: 2.056rem !default; // 37px
$FONT_SIZE_H4_DESKTOP: 1.722rem !default; // 31px
$FONT_SIZE_H5_DESKTOP: 1.389rem !default; // 25px
$FONT_SIZE_H6_DESKTOP: 1.222rem !default; // 22px
$FONT_SIZE_H1_HYPE: 5.555rem !default; // ~100px
$FONT_SIZE_H2_HYPE: 4.611rem !default; // ~83px
$FONT_SIZE_H3_HYPE: 3.833rem !default; // ~69px
$FONT_SIZE_H4_HYPE: 3.222rem !default; // ~58px
$FONT_SIZE_H5_HYPE: 2.666rem !default; // ~48px
$FONT_SIZE_H6_HYPE: 2.222rem !default; // ~40px
$FONT_SIZE_NAVIGATION: 15px !default;
$FONT_SIZE_PARAGRAPH: 1.0rem !default;
$FONT_SIZE_PARAGRAPH_SMALL: 0.875rem !default; // ~14px
$FONT_SIZE_PARAGRAPH_LARGE: 1.1111rem !default; // ~20px
$FONT_SIZE_BUTTON: $FONT_SIZE_BASE !default;
$FONT_SIZE_BUTTON_PROMO: 24px !default;

$FONT_SIZE_SUB: 0.875rem !default; // ~14px
$FONT_SIZE_MIN: 0.750rem !default; // ~12px

$FONT_SIZE_FORM: $FONT_SIZE_BASE !default;
$FONT_SIZE_FORM_LABEL: $FONT_SIZE_SUB !default;

$FONT_WEIGHT_BASE: 400 !default;
$FONT_WEIGHT_H1: 700 !default;
$FONT_WEIGHT_H2: $FONT_WEIGHT_H1 !default;
$FONT_WEIGHT_H3: $FONT_WEIGHT_H1 !default;
$FONT_WEIGHT_H4: $FONT_WEIGHT_H1 !default;
$FONT_WEIGHT_H5: $FONT_WEIGHT_H1 !default;
$FONT_WEIGHT_H6: $FONT_WEIGHT_BASE !default;
$FONT_WEIGHT_HYPE: 700 !default;
$FONT_WEIGHT_H1_ALTERNATE: $FONT_WEIGHT_H1 !default;
$FONT_WEIGHT_H2_ALTERNATE: $FONT_WEIGHT_H1_ALTERNATE !default;
$FONT_WEIGHT_H3_ALTERNATE: $FONT_WEIGHT_H1_ALTERNATE !default;
$FONT_WEIGHT_H4_ALTERNATE: $FONT_WEIGHT_H1_ALTERNATE !default;
$FONT_WEIGHT_H5_ALTERNATE: $FONT_WEIGHT_H1_ALTERNATE !default;
$FONT_WEIGHT_H6_ALTERNATE: $FONT_WEIGHT_BASE !default;
$FONT_WEIGHT_NAVIGATION: 700 !default;
$FONT_WEIGHT_BUTTON: 700 !default;
$FONT_WEIGHT_TAB: $FONT_WEIGHT_BASE !default;
$FONT_WEIGHT_TAB_HOVER: 700 !default;

$TEXT_TRANSFORM_BASE: none !default;
$TEXT_TRANSFORM_H1: $TEXT_TRANSFORM_BASE !default;
$TEXT_TRANSFORM_H2: $TEXT_TRANSFORM_H1 !default;
$TEXT_TRANSFORM_H3: $TEXT_TRANSFORM_H2 !default;
$TEXT_TRANSFORM_H4: $TEXT_TRANSFORM_H3 !default;
$TEXT_TRANSFORM_H5: $TEXT_TRANSFORM_H4 !default;
$TEXT_TRANSFORM_H6: $TEXT_TRANSFORM_H5 !default;
$TEXT_TRANSFORM_HYPE: uppercase !default;
$TEXT_TRANSFORM_H1_ALTERNATE: uppercase !default;
$TEXT_TRANSFORM_H2_ALTERNATE: $TEXT_TRANSFORM_H1_ALTERNATE !default;
$TEXT_TRANSFORM_H3_ALTERNATE: $TEXT_TRANSFORM_H2_ALTERNATE !default;
$TEXT_TRANSFORM_H4_ALTERNATE: $TEXT_TRANSFORM_H3_ALTERNATE !default;
$TEXT_TRANSFORM_H5_ALTERNATE: $TEXT_TRANSFORM_H4_ALTERNATE !default;
$TEXT_TRANSFORM_H6_ALTERNATE: $TEXT_TRANSFORM_H5_ALTERNATE !default;
$TEXT_TRANSFORM_NAVIGATION: uppercase !default;
$TEXT_TRANSFORM_BUTTON: uppercase !default;

$LINE_HEIGHT_BASE: 1.6 !default;
$LINE_HEIGHT_H1: 1.3 !default;
$LINE_HEIGHT_H2: 1.25 !default;
$LINE_HEIGHT_H3: $LINE_HEIGHT_H2 !default;
$LINE_HEIGHT_H4: $LINE_HEIGHT_H3 !default;
$LINE_HEIGHT_H5: $LINE_HEIGHT_H4 !default;
$LINE_HEIGHT_H6: $LINE_HEIGHT_H5 !default;
$LINE_HEIGHT_H1_HYPE: 1.3 !default;
$LINE_HEIGHT_H2_HYPE: 1.4 !default;
$LINE_HEIGHT_H3_HYPE: $LINE_HEIGHT_H2_HYPE !default;
$LINE_HEIGHT_H4_HYPE: $LINE_HEIGHT_H3_HYPE !default;
$LINE_HEIGHT_H5_HYPE: $LINE_HEIGHT_H4_HYPE !default;
$LINE_HEIGHT_H6_HYPE: $LINE_HEIGHT_H5_HYPE !default;
$LINE_HEIGHT_H1_ALTERNATE: 1.3 !default;
$LINE_HEIGHT_H2_ALTERNATE: 1.4 !default;
$LINE_HEIGHT_H3_ALTERNATE: $LINE_HEIGHT_H2_ALTERNATE !default;
$LINE_HEIGHT_H4_ALTERNATE: $LINE_HEIGHT_H3_ALTERNATE !default;
$LINE_HEIGHT_H5_ALTERNATE: $LINE_HEIGHT_H4_ALTERNATE !default;
$LINE_HEIGHT_H6_ALTERNATE: $LINE_HEIGHT_H5_ALTERNATE !default;
$LINE_HEIGHT_PARAGRAPH: $LINE_HEIGHT_BASE !default;
$LINE_HEIGHT_PARAGRAPH_SMALL: 1.6 !default;
$LINE_HEIGHT_PARAGRAPH_LARGE: $LINE_HEIGHT_PARAGRAPH !default;
$LINE_HEIGHT_FORM_LABEL: 1.0 !default;

$MARGIN_LIST_BASE: 4px !default;

@mixin TYPOGRAPHY_BASE {
    font-family: $FONT_FAMILY_BODY;
    font-size: $FONT_SIZE_BASE;
    line-height: $LINE_HEIGHT_BASE;
    @include breakpoint-desktop-up {
        font-size: $FONT_SIZE_DESKTOP;
    }
}
@mixin TYPOGRAPHY_H1_HYPE {
    font-family: var(--font-family-title, $FONT_FAMILY_TITLE);
    font-size: $FONT_SIZE_H1_HYPE;
    font-weight: $FONT_WEIGHT_HYPE;
    line-height: $LINE_HEIGHT_H1_HYPE;
    text-transform: $TEXT_TRANSFORM_HYPE;
}
@mixin TYPOGRAPHY_H2_HYPE {
    font-family: var(--font-family-title, $FONT_FAMILY_TITLE);
    font-size: $FONT_SIZE_H2_HYPE;
    font-weight: $FONT_WEIGHT_HYPE;
    line-height: $LINE_HEIGHT_H2_HYPE;
    text-transform: $TEXT_TRANSFORM_HYPE;
}
@mixin TYPOGRAPHY_H3_HYPE {
    font-family: var(--font-family-title, $FONT_FAMILY_TITLE);
    font-size: $FONT_SIZE_H3_HYPE;
    font-weight: $FONT_WEIGHT_HYPE;
    line-height: $LINE_HEIGHT_H3_HYPE;
    text-transform: $TEXT_TRANSFORM_HYPE;
}
@mixin TYPOGRAPHY_H4_HYPE {
    font-family: var(--font-family-title, $FONT_FAMILY_TITLE);
    font-size: $FONT_SIZE_H4_HYPE;
    font-weight: $FONT_WEIGHT_HYPE;
    line-height: $LINE_HEIGHT_H4_HYPE;
    text-transform: $TEXT_TRANSFORM_HYPE;
}
@mixin TYPOGRAPHY_H5_HYPE {
    font-family: var(--font-family-title, $FONT_FAMILY_TITLE);
    font-size: $FONT_SIZE_H5_HYPE;
    font-weight: $FONT_WEIGHT_HYPE;
    line-height: $LINE_HEIGHT_H5_HYPE;
    text-transform: $TEXT_TRANSFORM_HYPE;
}
@mixin TYPOGRAPHY_H6_HYPE {
    font-family: var(--font-family-title, $FONT_FAMILY_TITLE);
    font-size: $FONT_SIZE_H6_HYPE;
    font-weight: $FONT_WEIGHT_HYPE;
    line-height: $LINE_HEIGHT_H6_HYPE;
    text-transform: $TEXT_TRANSFORM_HYPE;
}
@mixin TYPOGRAPHY_H1 {
    font-family: var(--font-family-title, $FONT_FAMILY_TITLE);
    font-size: $FONT_SIZE_H1;
    font-weight: $FONT_WEIGHT_H1;
    line-height: $LINE_HEIGHT_H1;
    text-transform: $TEXT_TRANSFORM_H1;
    @include breakpoint-tablet-up {
        font-size: $FONT_SIZE_H1_DESKTOP;
    }
}
@mixin TYPOGRAPHY_H2 {
    font-family: var(--font-family-title, $FONT_FAMILY_TITLE);
    font-size: $FONT_SIZE_H2;
    font-weight: $FONT_WEIGHT_H2;
    line-height: $LINE_HEIGHT_H2;
    text-transform: $TEXT_TRANSFORM_H2;
    @include breakpoint-tablet-up {
        font-size: $FONT_SIZE_H2_DESKTOP;
    }
}
@mixin TYPOGRAPHY_H3 {
    font-family: var(--font-family-title, $FONT_FAMILY_TITLE);
    font-size: $FONT_SIZE_H3;
    font-weight: $FONT_WEIGHT_H3;
    line-height: $LINE_HEIGHT_H3;
    text-transform: $TEXT_TRANSFORM_H3;
    @include breakpoint-tablet-up {
        font-size: $FONT_SIZE_H3_DESKTOP;
    }
}
@mixin TYPOGRAPHY_H4 {
    font-family: var(--font-family-title, $FONT_FAMILY_TITLE);
    font-size: $FONT_SIZE_H4;
    font-weight: $FONT_WEIGHT_H4;
    line-height: $LINE_HEIGHT_H4;
    text-transform: $TEXT_TRANSFORM_H4;
    @include breakpoint-tablet-up {
        font-size: $FONT_SIZE_H4_DESKTOP;
    }
}
@mixin TYPOGRAPHY_H5 {
    font-family: var(--font-family-subtitle, $FONT_FAMILY_BODY);
    font-size: $FONT_SIZE_H5;
    font-weight: $FONT_WEIGHT_H5;
    line-height: $LINE_HEIGHT_H5;
    text-transform: $TEXT_TRANSFORM_H5;
    @include breakpoint-tablet-up {
        font-size: $FONT_SIZE_H5_DESKTOP;
    }
}
@mixin TYPOGRAPHY_H6 {
    font-family: var(--font-family-subtitle, $FONT_FAMILY_BODY);
    font-size: $FONT_SIZE_H6;
    font-weight: $FONT_WEIGHT_H6;
    line-height: $LINE_HEIGHT_H6;
    text-transform: $TEXT_TRANSFORM_H6;
    @include breakpoint-tablet-up {
        font-size: $FONT_SIZE_H6_DESKTOP;
    }
}
@mixin TYPOGRAPHY_H1_ALTERNATE {
    font-family: var(--font-family-title, $FONT_FAMILY_TITLE);
    font-size: $FONT_SIZE_H1;
    font-weight: $FONT_WEIGHT_H1_ALTERNATE;
    line-height: $LINE_HEIGHT_H1_ALTERNATE;
    text-transform: $TEXT_TRANSFORM_H1_ALTERNATE;
    @include breakpoint-tablet-up {
        font-size: $FONT_SIZE_H1_DESKTOP;
    }
}
@mixin TYPOGRAPHY_H2_ALTERNATE {
    font-family: var(--font-family-subtitle, $FONT_FAMILY_BODY);
    font-size: $FONT_SIZE_H2;
    font-weight: $FONT_WEIGHT_H2_ALTERNATE;
    line-height: $LINE_HEIGHT_H2_ALTERNATE;
    text-transform: $TEXT_TRANSFORM_H2_ALTERNATE;
    @include breakpoint-tablet-up {
        font-size: $FONT_SIZE_H2_DESKTOP;
    }
}
@mixin TYPOGRAPHY_H3_ALTERNATE {
    font-family: var(--font-family-subtitle, $FONT_FAMILY_BODY);
    font-size: $FONT_SIZE_H3;
    font-weight: $FONT_WEIGHT_H3_ALTERNATE;
    line-height: $LINE_HEIGHT_H3_ALTERNATE;
    text-transform: $TEXT_TRANSFORM_H3_ALTERNATE;
    @include breakpoint-tablet-up {
        font-size: $FONT_SIZE_H3_DESKTOP;
    }
}
@mixin TYPOGRAPHY_H4_ALTERNATE {
    font-family: var(--font-family-subtitle, $FONT_FAMILY_BODY);
    font-size: $FONT_SIZE_H4;
    font-weight: $FONT_WEIGHT_H4_ALTERNATE;
    line-height: $LINE_HEIGHT_H4_ALTERNATE;
    text-transform: $TEXT_TRANSFORM_H4_ALTERNATE;
    @include breakpoint-tablet-up {
        font-size: $FONT_SIZE_H4_DESKTOP;
    }
}
@mixin TYPOGRAPHY_H5_ALTERNATE {
    font-family: var(--font-family-subtitle, $FONT_FAMILY_BODY);
    font-size: $FONT_SIZE_H5;
    font-weight: $FONT_WEIGHT_H5_ALTERNATE;
    line-height: $LINE_HEIGHT_H5_ALTERNATE;
    text-transform: $TEXT_TRANSFORM_H5_ALTERNATE;
    @include breakpoint-tablet-up {
        font-size: $FONT_SIZE_H5_DESKTOP;
    }
}
@mixin TYPOGRAPHY_H6_ALTERNATE {
    font-family: var(--font-family-subtitle, $FONT_FAMILY_BODY);
    font-size: $FONT_SIZE_H6;
    font-weight: $FONT_WEIGHT_H6_ALTERNATE;
    line-height: $LINE_HEIGHT_H6_ALTERNATE;
    text-transform: $TEXT_TRANSFORM_H6_ALTERNATE;
    @include breakpoint-tablet-up {
        font-size: $FONT_SIZE_H6_DESKTOP;
    }
}

@mixin TYPOGRAPHY_PARAGRAPH {
    font-family: var(--font-family-body, $FONT_FAMILY_BODY);
    font-size: $FONT_SIZE_PARAGRAPH;
    line-height: $LINE_HEIGHT_PARAGRAPH;
}
@mixin TYPOGRAPHY_PARAGRAPH_SMALL {
    font-family: var(--font-family-body, $FONT_FAMILY_BODY);
    font-size: $FONT_SIZE_PARAGRAPH_SMALL;
    line-height: $LINE_HEIGHT_PARAGRAPH_SMALL;
}
@mixin TYPOGRAPHY_PARAGRAPH_LARGE {
    font-family: var(--font-family-body, $FONT_FAMILY_BODY);
    font-size: $FONT_SIZE_PARAGRAPH_LARGE;
    line-height: $LINE_HEIGHT_PARAGRAPH_LARGE;
}

@mixin TYPOGRAPHY_BUTTON {
    font-family: var(--font-family-button, $FONT_FAMILY_BUTTON);
    font-size: $FONT_SIZE_BUTTON;
    font-weight: $FONT_WEIGHT_BUTTON;
    text-transform: $TEXT_TRANSFORM_BUTTON;
}
