.Icon {
    display: inline-block;
    height: $SIZE_ICON;
    aspect-ratio: 1;

    &--raster {
        height: $SIZE_ICON_RASTER;
    }
    &--videoOverlay {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 40%;
        transform: translate(-50%, -50%);
        opacity: 0.5;
        pointer-events: none;
    }

    &.is-inverted {
        filter: invert(1);
    }
    &.is-inline {
        vertical-align: text-top;
    }
}
