.FormSelect {
    -moz-appearance: none;
    -webkit-appearance: none;

    @include FORM_FIELD_STYLES(true, $SPACE_MEDIUM $SPACE_SMALL+$SIZE_ICON+$SPACE_MEDIUM $SPACE_MEDIUM $SPACE_MEDIUM);

    option {
        background-color: $COLOR_FORM_BACKGROUND_DROPDOWN;
        color: $COLOR_FORM_FILL;

        &:hover {
            background-color: $COLOR_FORM_BACKGROUND_DROPDOWN_HOVER;
        }
    }

    &::-ms-expand {
        display: none;
    }
}
.FormSelectWrapper {
    @include FORM_FIELD_WRAPPER_STYLES;

    &-content {
        &:after {
            content: "";
            position: absolute;
            right: $SPACE_SMALLER;
            top: 50%;
            height: $SIZE_ICON;
            width: $SIZE_ICON;
            transform: translateY(-50%);
            pointer-events: none;
            background-image: url(/images/icons/material/dropdown.svg);
            background-size: $SIZE_ICON $SIZE_ICON;
            opacity: 70%;
            z-index: 1;
        }
    }

    &.is-icon {
        .FormSelect {
            padding-left: $SPACE_MEDIUM + $SIZE_ICON + $SPACE_SMALL;
        }

        .FormSelectWrapper-content {
            &:before {
                @include FORM_FIELD_ICON_STYLES;
            }
        }
    }
}
