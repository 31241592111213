.SectionBackground {
    @include FULL_COVER;

    background: center / cover no-repeat;

    &--masthead {
        background-position: top;

        @include breakpoint-tablet-down {
            bottom: auto;
            height: var(--height-masthead-tablet, 60vw);
        }
        @include breakpoint-mobile-only {
            height: var(--height-masthead-mobile, 80vw);
        }
    }

    &--top {
        background-position: top;

        @include breakpoint-tablet-down {
            mask-image: linear-gradient(to bottom, black 80%, transparent);
            bottom: auto;
            height: 60vw;
        }
        @include breakpoint-mobile-only {
            height: 80vw;
        }
    }

    &--chevron {
        background-position: top;

        @include MASTHEAD_CHEVRON_FULL;

        @include breakpoint-desktop-up {
            bottom: auto;
            height: $MASTHEAD_HEIGHT_MAX;
        }
    }
}
