.SubNavigation {
    position: relative;
    text-align: center;

    a,
    a:hover,
    a:active {
        text-decoration: none;
    }

    &-wrapper {
        position: relative;
        background-color: var(--color-subnavigation-background, #{$COLOR_INVERTED_BACKGROUND});
    }

    &-scroller {
        position: relative;
        scroll-behavior: smooth;
        padding: 0 $SPACE_MEDIUM;
    }

    &-content {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        white-space: nowrap;
        padding: 0;
        margin: 0;
    }

    &-down {
        display: none;
        position: absolute;
        top: $SPACE_LARGE - $SPACE_SMALLER;
        right: $SPACE_LARGE;
        filter: invert(1);
        cursor: pointer;
    }

    &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-underline {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        background-color: var(--color-subnavigation-underline, #{$COLOR_GOLD});

        &.is-animated {
            transition: all $TRANSITION_FAST;
        }
    }

    &.is-fixed {
        .SubNavigation-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 100;
        }
    }

    &.is-collapsed {
        .SubNavigation {
            @include breakpoint-tablet-up {
                padding-top: $SPACE_LARGE;
            }

            &-content {
                flex-direction: column;

                .SubNavigationItem {
                    display: none;

                    &.is-active {
                        display: block;
                    }
                }
            }

            &-underline {
                display: none;
            }

            &-down {
                display: block;
            }
        }
    }

    &.is-collapsed.is-open {
        .SubNavigation {
            &-content {
                width: 100%;

                .SubNavigationItem {
                    display: block;
                }
            }

            &-icon {
                transform: translate(-50%, -50%) rotate(180deg);
            }
        }
    }
}