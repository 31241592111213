.MusicLinks {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    gap: $GRID_GAP;

    &-column {
        display: flex;
        flex-flow: column nowrap;
        gap: $SPACE_SMALL;
    }
    &-heading {
        color: var(--color-text);
        @include TYPOGRAPHY_H5;
    }
}

.MusicLink {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: $SPACE_SMALL;
    text-decoration: underline;
    --color-link: var(--color-text);

    &-icon {
        width: 25px;
        height: 25px;
        border: 0;
        object-fit: cover;
    }
    &-label {

    }
}
