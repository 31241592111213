.Slideshow {
    position: relative;

    &--background {
        @include FULL_COVER;
    }
}

.SlideshowSlide {
    @include FULL_COVER;

    opacity: 0;
    transition: opacity 1s;

    background: center / cover no-repeat;

    &.is-active {
        opacity: 1;
    }
}
