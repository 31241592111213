@use "sass:math" as math;

// Theme colors here
// Note that they are *not* overrideable, they should not be directly
// used in any components, instead use semantic variables below.
$COLOR_WHITE: #FFFFFF;
$COLOR_VERY_LIGHT_GRAY: #FEFEFE;
$COLOR_LIGHT_GRAY: #F6F6F6;
$COLOR_DISABLED_GRAY: #DDDDDD;
$COLOR_MEDIUM_GRAY: #C8C8C8;
$COLOR_DARK_GRAY: #565656;
$COLOR_DARKER_GRAY: #4F4F4F;
$COLOR_VERY_DARK_GRAY: #272727;
$COLOR_EXTREMELY_DARK_GRAY: #18151C;
$COLOR_BLACK: #000000;

$COLOR_SUBTLE_GRAY: $COLOR_LIGHT_GRAY;
$COLOR_MID_GRAY: #D9D9D9;

$COLOR_VERY_LIGHT_RED: #F9EBEB;
$COLOR_LIGHT_RED: #FF6161;
$COLOR_DARK_RED: #940000;
$COLOR_HEADLINE_RED: #B20A0A;
$COLOR_RED: #B30000;

$COLOR_PRIME_GOLD: #DDAF49;
$COLOR_GOLD: #DDC57D;
$COLOR_COPPER: #EAAE78;
$COLOR_MEDIUM_BROWN: #635636;
$COLOR_BROWN: #3E3524;

$COLOR_LOTUS_AQUA: #136387;
$COLOR_LOTUS_LIGHT: #65C4ED;

$COLOR_PLATINUM_LIGHT: #30586F; // Used against light backgrounds
$COLOR_PLATINUM_DARK: #B5FFF6; // Used against dark backgrounds

// Platform color codes. These shouldn't have to change by theme either.
$COLOR_PLATFORM_PC: $COLOR_DARK_GRAY;
$COLOR_PLATFORM_PLAYSTATION: #184092;
$COLOR_PLATFORM_XBOX: #2D611F;
$COLOR_PLATFORM_NINTENDO: #A92319;
$COLOR_PLATFORM_MOBILE: #4b0a8f;
$COLOR_PLATFORM_IOS: #000000;
$COLOR_PLATFORM_ANDROID: #2FA94E;
$COLOR_PLATFORM_STEAM: #0A1935;

$COLOR_PLATFORM_TWITCH: #533E96;
$COLOR_PLATFORM_TWITCH_HOVER: #6451A0;
$FILTER_PLATFORM_TWITCH: invert(24%) sepia(49%) saturate(1527%) hue-rotate(226deg) brightness(92%) contrast(91%);
$COLOR_PLATFORM_AMAZON_PRIME: #00A8E1;
$FILTER_PLATFORM_AMAZON_PRIME: invert(60%) sepia(58%) saturate(5108%) hue-rotate(162deg) brightness(97%) contrast(101%);
$FILTER_CROSS_SAVE: invert(7%) sepia(84%) saturate(7135%) hue-rotate(4deg) brightness(104%) contrast(110%);
// Measurements
$SPACE_BASE: 8px !default;

$SPACE_MINIMUM: math.div($SPACE_BASE, 8);
$SPACE_THIN: math.div($SPACE_BASE, 4);
$SPACE_SMALLER: math.div($SPACE_BASE, 2);
$SPACE_SMALL: $SPACE_BASE;
$SPACE_MEDIUM: $SPACE_BASE * 2;
$SPACE_LARGE: $SPACE_BASE * 4;
$SPACE_LARGER: $SPACE_BASE * 8;
$SPACE_HUGE: $SPACE_BASE * 16;

// Typography and icons
$COLOR_TEXT: $COLOR_BLACK !default;
$COLOR_TITLE: $COLOR_BLACK !default;
$COLOR_SUBTITLE: $COLOR_DARK_GRAY !default;
$COLOR_ICON: $COLOR_BLACK !default;
$COLOR_ACCENT: $COLOR_RED !default;

$COLOR_TEXT_LEGAL: $COLOR_DARK_GRAY !default;
$COLOR_TEXT_FOOTER: $COLOR_BLACK !default;

$COLOR_INVERTED_TEXT: $COLOR_WHITE !default;
$COLOR_INVERTED_TITLE: $COLOR_WHITE !default;
$COLOR_INVERTED_SUBTITLE: $COLOR_MEDIUM_GRAY !default;
$COLOR_INVERTED_ICON: $COLOR_WHITE !default;

$COLOR_INVERTED_TEXT_FOOTER: $COLOR_WHITE !default;

$COLOR_LIVE: #F00 !default;
$COLOR_ERROR: $COLOR_DARK_RED !default;
$COLOR_INVERTED_ERROR: $COLOR_RED !default;

$COLOR_LINK: $COLOR_LOTUS_AQUA !default;
$COLOR_LINK_HOVER: $COLOR_LOTUS_AQUA !default;

$COLOR_LINK_INVERTED: $COLOR_LOTUS_LIGHT !default;

$SIZE_ICON: 20px !default;
$SIZE_ICON_RASTER: 24px !default;

// Buttons
$PADDING_BUTTON: 0 24px !default;
$PADDING_BUTTON_PROMO: 0 32px !default;
$BORDER_RADIUS_BUTTON: 4px !default;

$COLOR_BUTTON_PRIMARY_BACKGROUND: $COLOR_RED !default;
$COLOR_BUTTON_PRIMARY_BORDER: transparent !default;
$COLOR_BUTTON_PRIMARY_TEXT: $COLOR_WHITE !default;
$COLOR_BUTTON_PRIMARY_BACKGROUND_HOVER: darken($COLOR_BUTTON_PRIMARY_BACKGROUND, 10%) !default;
$COLOR_BUTTON_PRIMARY_BORDER_HOVER: $COLOR_BUTTON_PRIMARY_BORDER !default;
$COLOR_BUTTON_PRIMARY_TEXT_HOVER: $COLOR_BUTTON_PRIMARY_TEXT !default;
$FILTER_BUTTON_ICON_PRIMARY: invert(1) !default;
$FILTER_BUTTON_ICON_PRIMARY_HOVER: invert(1) !default;

$COLOR_BUTTON_SECONDARY_BACKGROUND: $COLOR_WHITE !default;
$COLOR_BUTTON_SECONDARY_BORDER: $COLOR_MEDIUM_GRAY !default;
$COLOR_BUTTON_SECONDARY_TEXT: $COLOR_BLACK !default;
$COLOR_BUTTON_SECONDARY_BACKGROUND_HOVER: $COLOR_BLACK !default;
$COLOR_BUTTON_SECONDARY_BORDER_HOVER: $COLOR_BLACK !default;
$COLOR_BUTTON_SECONDARY_TEXT_HOVER: $COLOR_WHITE !default;
$FILTER_BUTTON_ICON_SECONDARY: none !default;
$FILTER_BUTTON_ICON_SECONDARY_HOVER: invert(1) !default;

$COLOR_BUTTON_SECONDARY_INVERTED_BACKGROUND: $COLOR_BLACK !default;
$COLOR_BUTTON_SECONDARY_INVERTED_BORDER: $COLOR_BLACK !default;
$COLOR_BUTTON_SECONDARY_INVERTED_TEXT: $COLOR_WHITE !default;
$COLOR_BUTTON_SECONDARY_INVERTED_BACKGROUND_HOVER: $COLOR_DARK_GRAY !default;
$COLOR_BUTTON_SECONDARY_INVERTED_BORDER_HOVER: $COLOR_BUTTON_SECONDARY_INVERTED_BACKGROUND_HOVER !default;
$COLOR_BUTTON_SECONDARY_INVERTED_TEXT_HOVER: $COLOR_WHITE !default;
$FILTER_BUTTON_ICON_SECONDARY_INVERTED: invert(1) !default;
$FILTER_BUTTON_ICON_SECONDARY_INVERTED_HOVER: invert(1) !default;

$COLOR_BUTTON_DISABLED_TEXT: $COLOR_BLACK !default;
$COLOR_BUTTON_DISABLED_BACKGROUND: $COLOR_MEDIUM_GRAY !default;
$COLOR_BUTTON_DISABLED_BORDER: $COLOR_MEDIUM_GRAY !default;
$COLOR_BUTTON_DISABLED_TEXT_HOVER: $COLOR_BLACK !default;
$COLOR_BUTTON_DISABLED_BACKGROUND_HOVER: $COLOR_MEDIUM_GRAY !default;
$COLOR_BUTTON_DISABLED_BORDER_HOVER: $COLOR_MEDIUM_GRAY !default;
$FILTER_BUTTON_ICON_DISABLED: none !default;
$FILTER_BUTTON_ICON_DISABLED_HOVER: none !default;

$COLOR_BUTTON_SECONDARY_BACKGROUND_INVERTED: $COLOR_BLACK !default;
$COLOR_BUTTON_SECONDARY_BORDER_INVERTED: $COLOR_BLACK !default;
$COLOR_BUTTON_SECONDARY_TEXT_INVERTED: $COLOR_WHITE !default;

$SIZE_BUTTON_HEIGHT: 44px !default;
$SIZE_BUTTON_HEIGHT_PROMO: 56px !default;

$GAP_BUTTON: 16px !default;
$GAP_BUTTON_PROMO: 24px !default;

$TRANSITION_FAST: 250ms !default;
$TRANSITION_COLOR_BUTTON: $TRANSITION_FAST !default;
$TRANSITION_TRANSLATE_BUTTON: 350ms !default;

// Tabs
$COLOR_TAB_BACKGROUND: $COLOR_BLACK !default;
$COLOR_TAB_BORDER: transparent !default;
$COLOR_TAB_TEXT: $COLOR_WHITE !default;
$COLOR_TAB_BACKGROUND_ACTIVE: $COLOR_RED !default;
$COLOR_TAB_BORDER_ACTIVE: $COLOR_TAB_BORDER !default;
$COLOR_TAB_TEXT_ACTIVE: $COLOR_TAB_TEXT !default;
$COLOR_TAB_BACKGROUND_HOVER: $COLOR_RED !default;
$COLOR_TAB_BORDER_HOVER: $COLOR_TAB_BORDER !default;
$COLOR_TAB_TEXT_HOVER: $COLOR_TAB_TEXT !default;

$COLOR_TAB_DROPDOWN_BACKGROUND: #404040 !default;
$COLOR_TAB_DROPDOWN_BORDER: transparent !default;
$COLOR_TAB_DROPDOWN_TEXT: $COLOR_WHITE !default;

$BORDER_RADIUS_TAB: $BORDER_RADIUS_BUTTON !default;
$SIZE_TAB_HEIGHT: $SIZE_BUTTON_HEIGHT !default;
$PADDING_TAB: $PADDING_BUTTON !default;

$TRANSITION_TAB: $TRANSITION_FAST !default;

// Forms
$COLOR_FORM_BACKGROUND: $COLOR_LIGHT_GRAY !default;
$COLOR_FORM_BACKGROUND_DROPDOWN: $COLOR_WHITE !default;
$COLOR_FORM_BACKGROUND_DROPDOWN_HOVER: $COLOR_LIGHT_GRAY !default;
$COLOR_FORM_BORDER: $COLOR_MEDIUM_GRAY !default;
$COLOR_FORM_BORDER_ACTIVE: $COLOR_BLACK !default;
$COLOR_FORM_PLACEHOLDER: $COLOR_DARKER_GRAY !default;
$COLOR_FORM_FILL: $COLOR_BLACK !default;

$COLOR_FORM_BACKGROUND_ERROR: $COLOR_VERY_LIGHT_RED !default;
$COLOR_FORM_ERROR: $COLOR_ERROR !default;
$COLOR_FORM_BACKGROUND_CHECKBOX: $COLOR_FORM_FILL !default;
$COLOR_FORM_CHECKBOX: $COLOR_WHITE !default;

$COLOR_FORM_FILE_BACKGROUND: $COLOR_WHITE !default;
$COLOR_FORM_FILE_BUTTON: $COLOR_DARK_GRAY !default;
$COLOR_FORM_FILE_TEXT: $COLOR_DARK_GRAY !default;
$COLOR_FORM_FILE_TEXT_HOVER: $COLOR_WHITE !default;

$SIZE_FORM_CHECKBOX: 18px !default;
$SIZE_FORM_CHECKBOX_ICON: 16px !default;
$SIZE_FORM_RADIO_FILL: 6px !default;
$SIZE_FORM_FILE_ICON: 32px !default;

$BORDER_RADIUS_FORM: 0 !default;
$BORDER_RADIUS_FORM_FULL: $SPACE_SMALLER !default;

// Responsive
$BREAKPOINT_MOBILE: 375px !default;
$BREAKPOINT_TABLET: 768px !default;
$BREAKPOINT_DESKTOP: 1280px !default;
$BREAKPOINT_WIDE: 1920px !default;

$BREAKPOINT_MASTHEAD_MAX: 1440px !default;

// Layout
$COLOR_BACKGROUND: $COLOR_WHITE !default;
$COLOR_DIVIDER: $COLOR_MEDIUM_GRAY !default;

$COLOR_MOBILE_OVERLAY: rgba($COLOR_BLACK, 0.5) !default;

$COLOR_INVERTED_BACKGROUND: $COLOR_BLACK !default;
$COLOR_INVERTED_BACKGROUND_SECONDARY: $COLOR_EXTREMELY_DARK_GRAY !default;
$COLOR_INVERTED_DIVIDER: $COLOR_LIGHT_GRAY !default;

$COLOR_CONTAINER_LIGHT: $COLOR_WHITE !default;
$COLOR_CONTAINER_SUBTLE: $COLOR_SUBTLE_GRAY !default;
$COLOR_CONTAINER_LIGHT: $COLOR_BLACK !default;

$COLOR_MODAL_OVERLAY: fade-out($COLOR_BLACK, 0.5) !default;

$GRID_GAP: 24px !default;

$GRID_MOBILE_ONE_COLUMN: math.div(100%, 4);
$GRID_MOBILE_TWO_COLUMNS: 2 * $GRID_MOBILE_ONE_COLUMN;
$GRID_MOBILE_THREE_COLUMNS: 3 * $GRID_MOBILE_ONE_COLUMN;
$GRID_MOBILE_FOUR_COLUMNS: 100%;

$GRID_TABLET_ONE_COLUMN: math.div(100%, 8);
$GRID_TABLET_TWO_COLUMNS: 2 * $GRID_TABLET_ONE_COLUMN;
$GRID_TABLET_THREE_COLUMNS: 3 * $GRID_TABLET_ONE_COLUMN;
$GRID_TABLET_FOUR_COLUMNS: 4 * $GRID_TABLET_ONE_COLUMN;
$GRID_TABLET_FIVE_COLUMNS: 5 * $GRID_TABLET_ONE_COLUMN;
$GRID_TABLET_SIX_COLUMNS: 6 * $GRID_TABLET_ONE_COLUMN;
$GRID_TABLET_SEVEN_COLUMNS: 7 * $GRID_TABLET_ONE_COLUMN;
$GRID_TABLET_EIGHT_COLUMNS: 100%;

$GRID_DESKTOP_ONE_COLUMN: math.div(100%, 12);
$GRID_DESKTOP_TWO_COLUMNS: 2 * $GRID_DESKTOP_ONE_COLUMN;
$GRID_DESKTOP_THREE_COLUMNS: 3 * $GRID_DESKTOP_ONE_COLUMN;
$GRID_DESKTOP_FOUR_COLUMNS: 4 * $GRID_DESKTOP_ONE_COLUMN;
$GRID_DESKTOP_FIVE_COLUMNS: 5 * $GRID_DESKTOP_ONE_COLUMN;
$GRID_DESKTOP_SIX_COLUMNS: 6 * $GRID_DESKTOP_ONE_COLUMN;
$GRID_DESKTOP_SEVEN_COLUMNS: 7 * $GRID_DESKTOP_ONE_COLUMN;
$GRID_DESKTOP_EIGHT_COLUMNS: 8 * $GRID_DESKTOP_ONE_COLUMN;
$GRID_DESKTOP_NINE_COLUMNS: 9 * $GRID_DESKTOP_ONE_COLUMN;
$GRID_DESKTOP_TEN_COLUMNS: 10 * $GRID_DESKTOP_ONE_COLUMN;
$GRID_DESKTOP_ELEVEN_COLUMNS: 11 * $GRID_DESKTOP_ONE_COLUMN;
$GRID_DESKTOP_TWELVE_COLUMNS: 100%;

$CONTENT_MARGIN_MOBILE: 16px;
$CONTENT_WIDTH_MOBILE: $BREAKPOINT_MOBILE - ($CONTENT_MARGIN_MOBILE * 2); // 343
$CONTENT_GUTTER_MOBILE: 16px;

$CONTENT_MARGIN_TABLET: 32px;
$CONTENT_WIDTH_TABLET: $BREAKPOINT_TABLET - ($CONTENT_MARGIN_TABLET * 2); // 704
$CONTENT_GUTTER_TABLET: 24px;

$CONTENT_MARGIN_DESKTOP: 80px;
$CONTENT_WIDTH_DESKTOP: $BREAKPOINT_DESKTOP - ($CONTENT_MARGIN_DESKTOP * 2); // 1120
$CONTENT_GUTTER_DESKTOP: 24px;

$CONTENT_MARGIN_WIDE: 144px;
$CONTENT_WIDTH_WIDE: $BREAKPOINT_WIDE - ($CONTENT_MARGIN_WIDE * 2); // 1632
$CONTENT_GUTTER_WIDE: 24px;

$MASTHEAD_HEIGHT_MOBILE: 80vw;
$MASTHEAD_HEIGHT_TABLET: 60vw;
$MASTHEAD_HEIGHT_DESKTOP: 56.25vw;
$MASTHEAD_HEIGHT_WIDE: 56.25vw;
$MASTHEAD_HEIGHT_MAX: 810px;

$MASTHEAD_OVERLAP_MOBILE: 64px;
$MASTHEAD_OVERLAP_TABLET: 160px;

// @deprecated - only used by pre-2024 pages
$SIZE_SECTION_MARGIN: 5vw;
// @deprecated - only used by pre-2024 pages
$SIZE_SECTION_MARGIN_WIDE: 80px;

$SECTION_VERTICAL_PADDING_MOBILE: 60px;
$SECTION_VERTICAL_PADDING_TABLET: 80px;

$ALTERNATING_CONTENT_GAP: 24px;
$ALTERNATING_CONTENT_GAP_TABLET: $ALTERNATING_CONTENT_GAP 32px;
$ALTERNATING_CONTENT_GAP_DESKTOP: $ALTERNATING_CONTENT_GAP 64px;
$ALTERNATING_CONTENT_MARGIN_TABLET: 28px;
$ALTERNATING_CONTENT_MARGIN_DESKTOP: 56px;

$CARD_CONTENT_PADDING: $SPACE_MEDIUM $SPACE_MEDIUM #{$SPACE_MEDIUM+$SPACE_SMALL};

$Z_INDEX_FOOTER : 10 !default;
$Z_INDEX_NAV : 200 !default;
$Z_INDEX_MODAL : 500 !default;

// Navigation
$HEADER_MOBILE_HEIGHT: 58px;
$HEADER_TABLET_HEIGHT: 78px;
$HEADER_DESKTOP_HEIGHT: 66px;

$COLOR_NAVIGATION_BACKGROUND: rgba($COLOR_WHITE, 0.92) !default;
$COLOR_NAVIGATION_BACKGROUND_HOVER: $COLOR_WHITE !default;
$COLOR_NAVIGATION_BACKGROUND_SECONDARY: $COLOR_VERY_LIGHT_GRAY !default;
$COLOR_NAVIGATION_LINK: $COLOR_DARK_GRAY !default;
$COLOR_NAVIGATION_LINK_HOVER: $COLOR_BLACK !default;
$COLOR_NAVIGATION_LINK_BACKGROUND_HOVER: $COLOR_VERY_LIGHT_GRAY !default;
$COLOR_NAVIGATION_BACKGROUND_AVATAR: linear-gradient(to bottom left, #fff, #979797 50%, #bababa 100%) !default;
$BACKGROUND_NAVIGATION_BORDER: linear-gradient(to right, transparent, #9B8752) !default;
$SHADOW_NAVIGATION_DROPDOWN: 0 0 4px rgba(0,0,0,.4);
$BACKDROP_FILTER_NAVIGATION: blur(10px);

$COLOR_NAVIGATION_DARKMODE_BACKGROUND: rgba($COLOR_EXTREMELY_DARK_GRAY, 0.92) !default;
$COLOR_NAVIGATION_DARKMODE_BACKGROUND_HOVER: $COLOR_BLACK !default;
$COLOR_NAVIGATION_DARKMODE_BACKGROUND_FOOTER: $COLOR_BLACK !default;
$COLOR_NAVIGATION_DARKMODE_BACKGROUND_SECONDARY: $COLOR_EXTREMELY_DARK_GRAY !default;
$COLOR_NAVIGATION_DARKMODE_LINK: $COLOR_LIGHT_GRAY !default;
$COLOR_NAVIGATION_DARKMODE_LINK_HOVER: $COLOR_WHITE !default;
$COLOR_NAVIGATION_DARKMODE_LINK_BACKGROUND_HOVER: $COLOR_DARK_GRAY !default;
$COLOR_NAVIGATION_DARKMODE_BACKGROUND_AVATAR: linear-gradient(to bottom left, #000, #656565 50%, #545454 100%) !default;
$BACKGROUND_NAVIGATION_DARKMODE_BORDER: $COLOR_VERY_DARK_GRAY !default;

$COLOR_NAVIGATION_TEXT: $COLOR_TEXT !default;
$COLOR_NAVIGATION_DARKMODE_TEXT: $COLOR_INVERTED_TEXT !default;
$COLOR_NAVIGATION_NOTIFICATION: $COLOR_RED !default;
$COLOR_NAVIGATION_LINK_BAR: #F0F0F0 !default;
$COLOR_NAVIGATION_LANGUAGE_SELECTOR_BORDER: $COLOR_MEDIUM_GRAY !default;

$COLOR_NAVIGATION_DROPDOWN_SUBHEADING: $COLOR_MEDIUM_BROWN !default;
$COLOR_NAVIGATION_DROPDOWN_LINK: $COLOR_LINK !default;
$COLOR_NAVIGATION_DARKMODE_DROPDOWN_LINK: $COLOR_LINK_INVERTED !default;

// Navigation News Takeover
$COLOR_TAKEOVER_BACKGROUND: #07090B !default;
$COLOR_TAKEOVER_TEXT: #FFFFFF !default;
$COLOR_TAKEOVER_ACCENT: #F5E6A0 !default;
$COLOR_TAKEOVER_PANEL_BACKGROUND: #000000 !default;
$COLOR_TAKEOVER_PANEL_BORDER: #936C52 !default;
$COLOR_TAKEOVER_CLOSER: #7E5F4A !default;

$WIDTH_TAKEOVER_CARD: 320px !default;
$PADDING_TAKEOVER: $SPACE_LARGE !default;
$HEIGHT_TAKEOVER_INNER: 110px !default;
$HEIGHT_TAKEOVER_OUTER: $HEIGHT_TAKEOVER_INNER + (2 * $PADDING_TAKEOVER) !default;
$SPEED_TAKEOVER_TRANSITION: 1s !default;

// Footer
$COLOR_FOOTER_BACKGROUND: $COLOR_LIGHT_GRAY;
$COLOR_FOOTER_SOCIAL_LINK: $COLOR_GOLD !default;
