@mixin FIXED_COVER {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
@mixin FULL_COVER {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
@mixin FULL_COVER_WIDE_CAP {
    @include breakpoint-wide-up {
        left: 50%;
        right: auto;
        width: $BREAKPOINT_WIDE;
        transform: translateX(-50%);
    }
}
@mixin WIDE_EDGE_FADE {
    @include breakpoint-wide-up {
        mask-image: linear-gradient(to right, transparent, black 10% 90%, transparent);
    }
}

@mixin MASTHEAD_HEIGHT {
    @include breakpoint-mobile-only {
        height: $MASTHEAD_HEIGHT_MOBILE;
    }
    @include breakpoint-tablet-only {
        height: $MASTHEAD_HEIGHT_TABLET;
    }
    @include breakpoint-desktop-only {
        height: $MASTHEAD_HEIGHT_DESKTOP;
        max-height: $MASTHEAD_HEIGHT_MAX;
    }
    @include breakpoint-wide-up {
        height: $MASTHEAD_HEIGHT_WIDE;
        max-height: $MASTHEAD_HEIGHT_MAX;
    }
}

@mixin MASTHEAD_CHEVRON {
    aspect-ratio: 1920/80;
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    mask-image: url(/images/mask-chevron.svg);

    @include breakpoint-wide-up {
        aspect-ratio: auto;
        height: 80px;
    }
}

@mixin MASTHEAD_CHEVRON_FULL {
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    mask-position: bottom;
    mask-image: url(/images/mask-chevron-square.svg);

    @include breakpoint-tablet-up {
        mask-size: 100% auto;
    }
}

@mixin STANDARD_SECTION_PADDING {
    padding-top: $SECTION_VERTICAL_PADDING_MOBILE;
    padding-bottom: $SECTION_VERTICAL_PADDING_MOBILE;

    @include breakpoint-tablet-up {
        padding-top: $SECTION_VERTICAL_PADDING_TABLET;
        padding-bottom: $SECTION_VERTICAL_PADDING_TABLET;
    }
}

@mixin BUTTON_STYLES_FLAT {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;

    background: var(--color-button-background, $COLOR_BUTTON_PRIMARY_BACKGROUND);
    border: solid 1px var(--color-button-border, $COLOR_BUTTON_PRIMARY_BORDER);
    border-radius: $BORDER_RADIUS_BUTTON;
    min-height: $SIZE_BUTTON_HEIGHT;
    padding: $PADDING_BUTTON;

    color: var(--color-button-text, $COLOR_BUTTON_PRIMARY_TEXT);
    transition: $TRANSITION_COLOR_BUTTON;

    @include TYPOGRAPHY_BUTTON;
}
@mixin BUTTON_STYLES_STATES {
    &:not(:disabled) {
        cursor: pointer;
    }

    &:hover,
    &:active,
    &:focus {
        background: var(--color-button-background-hover, $COLOR_BUTTON_PRIMARY_BACKGROUND_HOVER);
        border-color: var(--color-button-border-hover, $COLOR_BUTTON_PRIMARY_BORDER_HOVER);
        color: var(--color-button-text-hover, $COLOR_BUTTON_PRIMARY_TEXT_HOVER);
    }

    &.is-promo {
        font-size: $FONT_SIZE_BUTTON_PROMO;
        min-height: $SIZE_BUTTON_HEIGHT_PROMO;
        padding: $PADDING_BUTTON_PROMO;
    }
    &.is-fullWidth {
        display: flex;
        width: 100%;
        text-align: center;
    }
}

@mixin BUTTON_STYLES {
    @include BUTTON_STYLES_FLAT;
    @include BUTTON_STYLES_STATES;
}
@mixin BUTTON_PRIMARY_COLORS {
    --color-button-background: #{$COLOR_BUTTON_PRIMARY_BACKGROUND};
    --color-button-border: #{$COLOR_BUTTON_PRIMARY_BORDER};
    --color-button-text: #{$COLOR_BUTTON_PRIMARY_TEXT};
    --color-button-background-hover: #{$COLOR_BUTTON_PRIMARY_BACKGROUND_HOVER};
    --color-button-border-hover: #{$COLOR_BUTTON_PRIMARY_BORDER_HOVER};
    --color-button-text-hover: #{$COLOR_BUTTON_PRIMARY_TEXT_HOVER};
    --filter-button-icon: #{$FILTER_BUTTON_ICON_PRIMARY};
    --filter-button-icon-hover: #{$FILTER_BUTTON_ICON_PRIMARY_HOVER};
}
@mixin BUTTON_SECONDARY_COLORS {
    --color-button-background: #{$COLOR_BUTTON_SECONDARY_BACKGROUND};
    --color-button-border: #{$COLOR_BUTTON_SECONDARY_BORDER};
    --color-button-text: #{$COLOR_BUTTON_SECONDARY_TEXT};
    --color-button-background-hover: #{$COLOR_BUTTON_SECONDARY_BACKGROUND_HOVER};
    --color-button-border-hover: #{$COLOR_BUTTON_SECONDARY_BORDER_HOVER};
    --color-button-text-hover: #{$COLOR_BUTTON_SECONDARY_TEXT_HOVER};
    --filter-button-icon: #{$FILTER_BUTTON_ICON_SECONDARY};
    --filter-button-icon-hover: #{$FILTER_BUTTON_ICON_SECONDARY_HOVER};
}
@mixin BUTTON_SECONDARY_INVERTED_COLORS {
    --color-button-background: #{$COLOR_BUTTON_SECONDARY_INVERTED_BACKGROUND};
    --color-button-border: #{$COLOR_BUTTON_SECONDARY_INVERTED_BORDER};
    --color-button-text: #{$COLOR_BUTTON_SECONDARY_INVERTED_TEXT};
    --color-button-background-hover: #{$COLOR_BUTTON_SECONDARY_INVERTED_BACKGROUND_HOVER};
    --color-button-border-hover: #{$COLOR_BUTTON_SECONDARY_INVERTED_BORDER_HOVER};
    --color-button-text-hover: #{$COLOR_BUTTON_SECONDARY_INVERTED_TEXT_HOVER};
    --filter-button-icon: #{$FILTER_BUTTON_ICON_SECONDARY_INVERTED};
    --filter-button-icon-hover: #{$FILTER_BUTTON_ICON_SECONDARY_INVERTED_HOVER};
}
@mixin BUTTON_SECONDARY_DISABLED_COLORS {
    --color-button-background: #{$COLOR_BUTTON_DISABLED_BACKGROUND};
    --color-button-border: #{$COLOR_BUTTON_DISABLED_BORDER};
    --color-button-text: #{$COLOR_BUTTON_DISABLED_TEXT};
    --color-button-background-hover: #{$COLOR_BUTTON_DISABLED_BACKGROUND_HOVER};
    --color-button-border-hover: #{$COLOR_BUTTON_DISABLED_BORDER_HOVER};
    --color-button-text-hover: #{$COLOR_BUTTON_DISABLED_TEXT_HOVER};
    --filter-button-icon: #{$FILTER_BUTTON_ICON_DISABLED};
    --filter-button-icon-hover: #{$FILTER_BUTTON_ICON_DISABLED_HOVER};
}

@mixin BADGE_STYLES {
    padding: $SPACE_SMALL;
    border-radius: $SPACE_SMALLER;
    font-size: $FONT_SIZE_MIN;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
}

@mixin LINK_NO_UNDERLINE {
    text-decoration: none;

    &:hover,
    &:active {
        text-decoration: none;
    }
}

@mixin INVERT_COLORS {
    --color-title: #{$COLOR_INVERTED_TITLE};
    --color-text: #{$COLOR_INVERTED_TEXT};
    --color-link: #{$COLOR_LINK_INVERTED};
    --color-link-hover: #{$COLOR_LINK_INVERTED};
    color: $COLOR_INVERTED_TEXT;
}

/*
@deprecated Should no longer be used
 */
@mixin BUTTON_ANIMATED_SWIPE($highColor:false, $textColor:false) {
    position: relative;
    overflow: hidden;
    transform: none;
    transition: filter $TRANSITION_COLOR_BUTTON ease-out, color $TRANSITION_TRANSLATE_BUTTON;

    &:before {
        z-index: -1;
        content: '';
        position: absolute;
        right: 0;
        width: calc(100% + 40px);
        top: 0;
        bottom: 0;
        @if $highColor {
            background-color: $highColor;
        }
        transform: skew(-10deg) translateX(-120%);
        transition: filter $TRANSITION_COLOR_BUTTON ease-out, transform $TRANSITION_TRANSLATE_BUTTON linear;
    }

    &:hover,
    &:active,
    &:focus {
        @if $textColor {
            color: $textColor;
        }
        filter: drop-shadow(0 0 0 $highColor);

        &:before {
            transform: skew(-10deg) translateX(20px);
        }

        .Icon {
            filter: invert(1);
        }
    }
}

@mixin FORM_BASIC_STYLES($fullBorder:false) {
    background-color: var(--form-background-color, #{$COLOR_FORM_BACKGROUND});
    color: var(--form-text-color, #{$COLOR_FORM_FILL});

    @if $fullBorder {
        border: solid 1px var(--form-border-color, #{$COLOR_FORM_BORDER});
        border-radius: $BORDER_RADIUS_FORM_FULL;
    } @else {
        border: 0;
        border-bottom: solid 1px var(--form-border-color, #{$COLOR_FORM_BORDER});
    }

    &:focus {
        outline: none;
        border-color: var(--form-border-color-active, #{$COLOR_FORM_BORDER_ACTIVE});
    }
    &.is-error {
        border-color: var(--form-border-color-error, #{$COLOR_FORM_ERROR});
    }
}

@mixin FORM_FIELD_STYLES($fullBorder:false,$padding:null) {
    font-family: var(--font-family-form, #{$FONT_FAMILY_FORM});
    font-size: var(--font-size-form, #{$FONT_SIZE_FORM});
    @if $padding {
        padding: $padding;
    } @else {
        padding: $SPACE_MEDIUM;
    }

    @include FORM_BASIC_STYLES($fullBorder);

    &::placeholder {
        color: var(--form-placeholder-color, #{$COLOR_FORM_PLACEHOLDER});
    }
    &.is-full {
        width: 100%;
    }
}

@mixin FORM_FIELD_ERROR_STYLES {
    color: $COLOR_FORM_ERROR;
    font-size: $FONT_SIZE_FORM;
}

@mixin FORM_FIELD_WRAPPER_STYLES {
    position: relative;
    display: inline;

    &-content {
        position: relative;
        display: inline;
    }
    &-error {
        @include FORM_FIELD_ERROR_STYLES;
    }
}
@mixin FORM_FIELD_ICON_STYLES {
    content: "";
    position: absolute;
    left: $SPACE_MEDIUM;
    top: 50%;
    height: $SIZE_ICON;
    width: $SIZE_ICON;
    transform: translateY(-50%);
    pointer-events: none;
    background-image: var(--input-icon);
    background-size: $SIZE_ICON $SIZE_ICON;
    opacity: 70%;
    z-index: 1;
}
@mixin MASK_WIDESCREEN_FADE {
    -webkit-mask-image: linear-gradient(to right, transparent 0, black 10%, black 90%, transparent 100%);
    mask-image: linear-gradient(to right, transparent 0, black 10%, black 90%, transparent 100%);
}

@mixin CONTENT_CONTRAST_FILTER {
    filter: drop-shadow(0 4px 4px rgba($COLOR_BLACK,.5));
}
